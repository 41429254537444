/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./general');
require('./components/navigation/navigation-hide-on-scroll.js');
require('./components/swiper/swiper.js');
require('./components/cookies/cookies.js');
