var swiper = new Swiper('.hero-slider', {
    // Optional parameters
    loop: false,
    // If we need pagination
    pagination: {
        el: '.hero-slider-fluid-1-pagination',
        clickable: true,
    },

    // Navigation arrows
    navigation: {
        nextEl: '.hero-slider-fluid-1-button-next',
        prevEl: '.hero-slider-fluid-1-button-prev',
    },
});

var swiper = new Swiper('.awards-slider-contained-1-slider', {
    // Optional parameters
    loop: true,
    slidesPerView: 6,
    spaceBetween: 30,

    // If we need pagination
    pagination: {
        el: '.awards-slider-contained-1-pagination',
    },

    // Navigation arrows
    navigation: {
        nextEl: '.awards-slider-contained-1-button-next',
        prevEl: '.awards-slider-contained-1-button-prev',
    },

    // Responsive breakpoints
    breakpoints: {
    // when window width is >= 320px
        0: {
            slidesPerView: 2,
            spaceBetween: 30
        },
        576: {
            slidesPerView: 3,
            spaceBetween: 30
        },
    // when window width is >= 480px
        768: {
            slidesPerView: 4,
            spaceBetween: 30
        },
    // when window width is >= 640px
        992: {
            slidesPerView: 6,
            spaceBetween: 30
        }
    }
});

var swiper = new Swiper('.gallery-contained-slider-1-slider', {
    // Optional parameters
    loop: true,
    slidesPerView: 4,
    spaceBetween: 30,
    centeredSlides: false,

    // If we need pagination
    pagination: {
        el: '.gallery-contained-slider-1-pagination',
    },
    scrollbar: {
        el: ".gallery-contained-slider-1-slider-scrollbar",
        draggable: true,
        hide: false,
    },
    // Navigation arrows
    navigation: {
        nextEl: '.gallery-contained-slider-1-button-next',
        prevEl: '.gallery-contained-slider-1-button-prev',
    },

    // Responsive breakpoints
    breakpoints: {
    // when window width is >= 320px
        0: {
            slidesPerView: 2,
            spaceBetween: 30
        },
        576: {
            slidesPerView: 3,
            spaceBetween: 30
        },
    // when window width is >= 480px
        768: {
            slidesPerView: 3,
            spaceBetween: 30
        },
    // when window width is >= 640px
        992: {
            slidesPerView: 4,
            spaceBetween: 30
        }
    }
});

var swiper = new Swiper('.gallery-fluid-slider-1-slider', {
    // Optional parameters
    loop: true,
    slidesPerView: 4.5,
    centeredSlides: true,
    spaceBetween: 30,

    // If we need pagination
    pagination: {
        el: '.gallery-fluid-slider-1-pagination',
    },

    // Navigation arrows
    navigation: {
        nextEl: '.gallery-fluid-slider-1-button-next',
        prevEl: '.gallery-fluid-slider-1-button-prev',
    },

    // Responsive breakpoints
    breakpoints: {
    // when window width is >= 320px
        0: {
            slidesPerView: 2,
            spaceBetween: 30
        },
        576: {
            slidesPerView: 3,
            spaceBetween: 30
        },
    // when window width is >= 480px
        768: {
            slidesPerView: 3,
            spaceBetween: 30
        },
    // when window width is >= 640px
        992: {
            slidesPerView: 4.5,
            spaceBetween: 30
        }
    }
});

