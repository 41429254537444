// Cookies Policy
let cookie = Cookies.get('cookie-bar');
if(cookie!=='true'){
    var myModal = new bootstrap.Modal(document.getElementById("cookieModal"), {});
    myModal.show();
}

$('#cookieAccept').on('click', function (e) {
  Cookies.set('cookie-bar', 'true', { expires: 14 });
});

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}